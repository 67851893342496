import React from "react"
import { Router } from "@reach/router"
import layoutStyle from '../staticdata/pageInfoData'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ContactUs from '../containers/ContactUsContainer'
import Home from '../containers/HomeContainer'
import AboutUs from '../containers/AboutUsContainer'
import DigitalEnabler from '../containers/DigitalEnablerContainer'
import Domains from '../containers/DomainsContainer'
import KnowledgeShare from '../containers/KnowledgeShareContainer'
import StartUpStudio from '../containers/StartupStudioContainer'
import BlogDetail from '../containers/BlogDetailContainer'

const IndexPage = (props) => {
    const selectedPage = layoutStyle[props && props.location.pathname]
    return (
        <Layout
            pageInfo={{ pageName: (selectedPage && selectedPage.pageName) || 'Blog Detail' }}
            styleClass={(selectedPage && selectedPage.styleClass) || 'our-story-page'}
            mainClass={(selectedPage && selectedPage.mainClass) || 'main-about'}
            location={props.location.pathname}>
            <SEO title="Home" keywords={(selectedPage && selectedPage.keywords) || [`gten`, `GTEN`, `technology`]} />
            <Router basepath="/">
                <ContactUs path="/contactus" />
                <Home path="/" />
                <AboutUs path="/aboutus" />
                <DigitalEnabler path="/productengineering" />
                <Domains path="/industries" />
                <KnowledgeShare path="/knowledgeshare" />
                <StartUpStudio path="/startupstudio" />
                <BlogDetail path="/blogdetail/:blogid" />
            </Router>
        </Layout>
    )
}

export default IndexPage
