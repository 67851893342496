export default  {
    '/aboutus/':{
    pagename:'About Us',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/domains/':{
    pagename:'Domains',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/contactus/':{
    pagename:'Contact Us',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/productengineering/':{
    pagename:'Digital Enabler',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/knowledgeshare/':{
    pagename:'Knowledge Share',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/startupstudio/':{
    pagename:'Startup Studio',
    styleClass:'our-story-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
},
'/':{
    pagename:'Home',
    styleClass:'home-page',
    mainClass:'main-about',
    keywords:[`gten`, `GTEN`, `technology`]
}}