import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import ContactUsSection from '../components/homepage/ContactUsSection'
import banner from '../images/slicing/our story/banner.png'
import { addSubscriberMail } from '../middleware/apis'
class ContactUsContainer extends Component {

    handleSubscribeEmail = (subscribeEmail) => {
        this.setState({ subscribeEmail })
    }
    submitSubscriptionForm = async () => {
        const { subscribeEmail } = this.state
        await addSubscriberMail({ email: subscribeEmail })
            .then((res) => {
                console.log("hello")
            })
            .catch(error => {
                console.log(error)
            })
        this.setState({
            subscribeEmail: ''
        })
    }
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Contact Us"
                    bannerImage={banner} />
                <ContactUsSection
                    page="contactus"
                    styles={{
                        padding: '30px',
                        boxShadow: '0px 16px 33.6px 6.4px rgba(0, 0, 0, 0.12)',
                        marginTop: '3rem',
                        marginBottom: '3rem'
                    }} />
            </>
        )
    }
}

export default ContactUsContainer